.v-application div.v-tooltip__content {
  opacity: 1 !important;
  border: 1px solid #fff !important;
  border-color: rgba(255, 255, 255, 0.7) !important;
  padding: 10px 18px !important;
}

/* Solo inverted text field icon color on select */
.v-text-field--solo-inverted.v-input--is-focused .v-icon.primary--text {
  color: #fff !important;
}

/* Text field left qr button */
.qrcode-button div.v-input__slot > div.v-input__prepend-inner > div {
  width: 26px;
  min-width: 26px;
  justify-content: left;
}